<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTRACT_TYPOLOGY_TYPES } from './store'
import { ROUTES as CONTRACT_TYPOLOGY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import ContractTypologyTable from './ContractTypologyTable'
import ContractTypologyCommonFilters from './ContractTypologyCommonFilters'
import ContractTypologyEdit from './ContractTypologyEdit.vue'

export default {
  name: 'ContractTypologyList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ContractTypologyTable,
    'common-filters': ContractTypologyCommonFilters,
    'component-edit': ContractTypologyEdit,
    'component-detail': ContractTypologyEdit
  },
  data () {
    return {
      CONTRACT_TYPOLOGY_ROUTES,
      title: this.$t('Contract typologies'),
      rolePerm: ['organization_contracttypology_list'],
      actionEnablePermission: ['organization_contracttypology_enable'],
      actionDisablePermission: ['organization_contracttypology_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 4 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 4 },
        { key: 'filter__slug__icontains', placeholder: this.$t('Slug'), type: 'text', col: 4 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CONTRACT_TYPOLOGY_TYPES.GENERIC.organization.contracttypology.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.CONTRACT_TYPOLOGY_ROUTES.CONTRACT_TYPOLOGY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contracttypology_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CONTRACT_TYPOLOGY_TYPES.GENERIC.organization.contracttypology.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CONTRACT_TYPOLOGY_TYPES.GENERIC.organization.contracttypology.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CONTRACT_TYPOLOGY_TYPES.GENERIC.organization.contracttypology.LIST.ACTIONS
    })
  }
}
</script>
